import React from 'react'
import { Link } from 'gatsby'
import * as _ from 'lodash'

import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  {
    allStrapiJobs(sort: { fields: published_at, order: DESC }) {
      nodes {
        title
        slug
				formattedPublishedDate: published_at(formatString: "MMMM D, YYYY")
        publishedFromNow: published_at(fromNow: true)
        address {
          streetAddress
          addressLocality
        }
      }
    }
  }
`

const BlogCard = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiJobs: { nodes: jobs },
  } = data

  return (
    <section className="jobs-area ptb-100">
      <div className="container">
        <div>
          {jobs.map((job, idx) => {
            let address = _.omitBy(_.get(job, 'address', {}), value => !value)
            address = _.join(_.values(address), ', ')

            return (
              <div key={idx}>
                <Link to={`/careers/${job.slug}`}>
                  <div className="single-job-post bg-fffbf5">
                    <div className="post-content">
                      <h3 className="mb-2">{job.title}</h3>
                      <div className="job-meta d-flex justify-content-between align-items-center">
                        <div>
                          <span>{address}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default BlogCard
